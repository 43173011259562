const LANGUAGE_CODE_ENGLISH_I18N = 'en'
const LANGUAGE_CODE_ENGLISH_AUTH0 = 'en'
const LANGUAGE_CODE_SPANISH_AUTH0 = 'es'

export const getAuth0LockLangCode = (i18nCurrentLanguage) => {
  switch (i18nCurrentLanguage) {
    // english
    case LANGUAGE_CODE_ENGLISH_I18N:
      return LANGUAGE_CODE_ENGLISH_AUTH0
    // spanish
    case LANGUAGE_CODE_SPANISH_AUTH0:
      return LANGUAGE_CODE_SPANISH_AUTH0
    // default to english
    default:
      return LANGUAGE_CODE_ENGLISH_AUTH0
  }
}
